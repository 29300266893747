var Flickity = require('flickity');
require('flickity-imagesloaded');

const testimonialComponent = () => {
    const el = document.querySelector('.testimonials-carousel');
    if (!el) {
        return;
    }

    const flkty = new Flickity(el, {
        contain: true,
        imagesLoaded: true,
        cellAlign: "center",
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
    });

    $('.testimonial-prev').on( 'click', function() {
        flkty.previous();
    });
    // previous wrapped
    $('.testimonial-next').on( 'click', function() {
        flkty.next();
    });
}

export default testimonialComponent;
