var Pikaday = require('pikaday');
var moment = require('moment');
const datepicker = () => {
    if ($('.datepicker').length) {
        $('.datepicker').each(function(index, el) {
            new Pikaday({
                field: el,
                format: 'DD/MM/YYYY'
            });
        });
    }
}

export default datepicker;
