/**
 * Include Dependencies
 */
const Vue = require('vue');
import './pushy/navigation';

Vue.component('our-team', {
    template: `
        <div class="lg:flex lg:bg-grey-lightest">
            <div class="links links--your-team bg-minf-dark w-full lg:w-1/4 lg:py-8 lg:border-r lg:border-grey-lighter lg:border-dashed xl:1/5">
                <a v-for="member in team" :href="member.url" :class="{'active': member.isActive}" class="navigation-item--link text-left" @mouseover="selectTeam(member)">{{ member.name }}</a>
            </div>

            <div class="hidden links links--content p-8 text-left w-3/4 lg:inline-block">
                <slot></slot>
            </div>
        </div>
    `,

    data() {
        return {
            team: []
        };
    },

    created() {
        this.team = this.$children;
    },

    methods: {
        selectTeam(selectedMember) {
            this.team.forEach(member => {
                member.isActive = (member.name == selectedMember.name)
            });
        }
    }
});

Vue.component('team', {
    template: `<div v-show="isActive"><slot></slot></div>`,

    props: {
        name: { required: true },
        selected: { default: false },
        url: { default: '#' },
    },

    data() {
        return {
            isActive: false
        };
    },

    mounted() {
        this.isActive = this.selected;
    }
});

new Vue({
    el: '#our-team'
});
