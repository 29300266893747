<template>
    <div class="tabs bg-white rounded p-4 md:p-6 lg:p-10 lg:mx-6">
        <ul class="list-reset py-4 flex justify-center lg:pb-10 tabs-items">
            <li v-for="(tab, index) in tabs" class="tabs-item">
                <a href="#" v-if="tabs.length > 1"
                    @click.prevent="selectedTab(tab)"
                    :class="{
                        'bg-mint text-white no-underline' : tab.isActive,
                        'text-grey-dark no-underline' : ! tab.isActive,
                        'border-r border-dashed' : ((tabs.length -1) != index),
                    }"
                    class="px-4 py-2 block w-full lg:p-4">
                    {{ tab.name }}
                </a>
                <span v-else
                    class="px-4 py-2 block w-full lg:p-4 text-grey-dark no-underline">
                    {{ tab.name }}
                </span>

            </li>
        </ul>
        <div class="tabs">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            tabs: []
        };
    },

    created() {
        this.tabs = this.$children;
        this.tabsCount = this.$children.length
    },

    methods: {
        selectedTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name)
            });
        }
    }
}
</script>
