let Vue = require('vue');
let imagesLoaded = require('imagesloaded');
let jQueryBridget = require('jquery-bridget');
let Masonry = require('masonry-layout');

jQueryBridget( 'masonry', Masonry, $ );

import heroComponent from '../components/carousel/hero/hero';
import testimonialComponent from '../components/testimonials/testimonial';
import '../components/navigation/navigation';
import '../components/modal/video/video';
import datepicker from '../components/datepicker/datepicker';
import Tabs from '../components/tabs/tabs.vue';
import Tab from '../components/tabs/tab.vue';

heroComponent();
testimonialComponent();
datepicker();

let $grid = $('.grid');
if ($grid.length) {
    $('.grid').imagesLoaded(function() {
        $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    });
}

const tabsElement = document.getElementById('tabs');
if (tabsElement) {
    new Vue({
        components: {Tabs, Tab},
        el: '#tabs'
    });
}
