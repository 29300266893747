var Flickity = require('flickity');
require('flickity-imagesloaded');

const heroComponent = () => {
    const el = document.querySelector('.hero-carousel');

    if (! el) {
        return;
    }

    var flkty = new Flickity(el, {
        contain: true,
        imagesLoaded: true,
        cellAlign: "center",
        pageDots: false,
        prevNextButtons: false,
        autoPlay: 5000,
        pauseAutoPlayOnHover: false
    });
}

export default heroComponent;
